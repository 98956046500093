import React from 'react';
import { Box, Button, Grid, Paper, Typography, useMediaQuery, Container } from '@mui/material';
import '../../Subscription.css';
import LandingPageInfo from './LandingPageInfo.js';
import { useNavigate } from 'react-router-dom';
import PromotionalVideo from './PromotionalVideo.js';
import { useTheme } from '@emotion/react';
import { useSubscriptionStageManager } from '../../../../hooks/useSubscriptionStageManager.js';
import { useAuthStore } from '../../../../stores/useAuthStore.js';

export default function SubscriptionLandingPage() {
  const { setStage } = useSubscriptionStageManager();
  const { isAuthenticated } = useAuthStore();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSignInToSubscribe = () => {
    navigate('/auth/sign-up');
  };

  const handleStageChange = () => {
    setStage(1);
    navigate('/subscription/form');
  };

  return (
    <Box sx={{ flexGrow: 1, p: isMobile ? 0 : 2, position: 'absolute', top: '70px' }}>
      <Typography variant="h4" textAlign={'left'} sx={{ borderBottom: '2px solid green' }}>
        Become a subscriber!
      </Typography>
      <Grid item xs={12} sx={{}}>
        <Box textAlign="center">
          {isAuthenticated ? (
            <Button
              color="primary"
              variant="contained"
              onClick={handleStageChange}
              sx={{ fontSize: '1.5rem', mt: '25px' }}
            >
              Purchase a Subscription
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              sx={{ margin: '1rem' }}
              onClick={handleSignInToSubscribe}
            >
              Sign-up to Subscribe
            </Button>
          )}
        </Box>
      </Grid>
      <Grid container spacing={4} alignItems="start" sx={{ padding: '20px' }}>
        {/* Text and Video Section */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            '&.MuiGrid-item': { paddingLeft: '15px' },
          }}
        >
          <PromotionalVideo />
          <Container>
            {' '}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
              <Box
                component="img"
                src="https://atf-main-storage.s3.us-west-2.amazonaws.com/atf-assets/logo-icon-6-192.png"
                alt="At The Fire Logo"
                sx={{ width: 80, height: 80, mr: 2 }}
              />
              <Box>
                <Typography variant="h3" gutterBottom>
                  At The Fire
                </Typography>
                <Typography variant="h6" sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                  A Gallery Platform for Artists and Collectors
                </Typography>
              </Box>
            </Box>
            <Typography variant="body1" paragraph sx={{ textAlign: 'left' }}>
              <em>At The Fire</em> is a subscription-based gallery site designed for artists to
              showcase their collections and offer their work for sale. Collectors are welcome to
              subscribe as well. The platform features a tiered subscription model—basic accounts
              are free, while a paid subscription unlocks business accounting and sales analysis
              tools. Artists can create posts, manage inventory, track sales, and analyze
              production.
            </Typography>
            <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
              Features
            </Typography>
            <Typography variant="h5" gutterBottom sx={{ mt: 3, textAlign: 'left' }}>
              Subscription Tiers
            </Typography>
            <Box sx={{ pl: 2, textAlign: 'left' }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Basic Account (Free):
              </Typography>
              <Typography paragraph>
                Allows users to log in and out, manage a profile, and view galleries.
              </Typography>

              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Paid Subscription:
              </Typography>
              <Box sx={{ pl: 2 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
                  Content Posting:
                </Typography>
                <Typography paragraph>
                  Create gallery posts that contribute to the inventory list
                </Typography>

                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Inventory Management:
                </Typography>
                <Typography paragraph>View inventory snapshots and download CSV files</Typography>

                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Orders & Goals:
                </Typography>
                <Typography paragraph>
                  Create/manage orders, track daily/monthly production quotas, and set goals
                </Typography>

                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Data Visualization:
                </Typography>
                <Typography paragraph>
                  Access graphical and tabular data analysis for sales and production
                </Typography>
              </Box>
            </Box>
          </Container>
          <Paper sx={{ padding: '15px 20px', mt: '50px' }}>
            {' '}
            <Typography sx={{ textAlign: 'left' }}>
              If you&apos;d like to take a deeper dive into how things work, check out our{' '}
              <span
                onClick={() => {
                  navigate('/user-guide');
                }}
                style={{ color: 'lightgreen', cursor: 'pointer' }}
              >
                User Guide
              </span>
              <br />
              <br />
              Click the button below to purchase a monthly or yearly subscription.
            </Typography>
          </Paper>
          {/* Action Button */}
          <Grid item xs={12} sx={{}}>
            <Box textAlign="center">
              {isAuthenticated ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleStageChange}
                  sx={{ fontSize: '1.5rem', mt: '25px' }}
                >
                  Purchase a Subscription
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ margin: '1rem' }}
                  onClick={handleSignInToSubscribe}
                >
                  Sign-up to Subscribe
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            overflow: 'hidden',
            '&.MuiGrid-item': { paddingLeft: '15px' },
          }}
        >
          <LandingPageInfo
            isMobile={isMobile}
            handleStageChange={handleStageChange}
            handleSignInToSubscribe={handleSignInToSubscribe}
          />
        </Grid>

        <Grid item xs={12}></Grid>
      </Grid>
    </Box>
  );
}
