import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../stores/useAuthStore.js';
import { useQuery } from '../context/QueryContext.js';
import { useNotificationStore } from '../stores/useNotificationStore.js';

export default function useAppBar({ setAnchorElNav, setAnchorElUser }) {
  const {
    customerId,
    setCustomerId,
    handleSignOut,
    setEmail,
    setPassword,
    setCPassword,
    email,
    setUser,
    setIsAuthenticated,
    user,
  } = useAuthStore();
  const navigate = useNavigate();

  const { unreadCount } = useNotificationStore();
  const mobileOpen = useNotificationStore((state) => state.mobileOpen);
  const setMobileOpen = useNotificationStore((state) => state.setMobileOpen);

  const { toggleFeedView, isFeedView } = useQuery();

  const handleLogoNavigation = () => {
    if (isFeedView) {
      toggleFeedView();
    }
    navigate('/');
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (e) => {
    switch (e.target.textContent) {
      case 'Gallery':
      case 'Feed':
        setAnchorElNav(null);
        toggleFeedView();
        navigate('/');
        break;
      case 'Sign Up':
        setAnchorElNav(null);
        navigate('/auth/sign-up');
        break;
      case 'Sign In':
        setAnchorElNav(null);
        navigate('/auth/sign-in');
        break;
      case 'About':
        setAnchorElNav(null);
        navigate('/about');
        break;
      case 'Contact':
        setAnchorElNav(null);
        navigate('/contact');
        break;
      default:
        setAnchorElNav(null);
        break;
    }
  };
  const messages = unreadCount === 0 ? 'Messages' : `Messages (${unreadCount})`;

  const handleCloseUserMenu = (e) => {
    switch (e.target.textContent) {
      case 'Logout':
        handleSignOut(email, setUser, setIsAuthenticated, setCustomerId);
        setEmail('');
        setPassword('');
        setCPassword('');
        setAnchorElUser(null);

        navigate('/auth/sign-in');
        return;

      case 'Subscription':
        if (customerId === null || customerId === undefined) {
          navigate('/subscription');
          setAnchorElUser(null);
        } else {
          navigate('/dashboard');
          setAnchorElUser(null);
        }
        break;
      case messages:
        navigate(`/messages`);
        if (!mobileOpen) setMobileOpen(true);
        setAnchorElUser(null);
        break;
      case 'Workshop':
        navigate('/dashboard');
        setAnchorElUser(null);
        break;
      case 'Profile':
        navigate(`/profile/${user}`);
        setAnchorElUser(null);
        break;
      case 'User Guide':
        navigate(`/user-guide`);
        setAnchorElUser(null);
        break;
      default:
        break;
    }
    setAnchorElUser(null);
  };

  return {
    handleLogoNavigation,
    handleOpenNavMenu,
    handleOpenUserMenu,
    handleCloseNavMenu,
    handleCloseUserMenu,
  };
}
